(window as any).global = window;

import 'core-js/features/array/find-last';

/** Evergreen browsers require these. **/
import 'core-js/es/reflect';
import 'core-js/es/symbol';
import 'core-js/es/promise';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';  // Run `npm install --save web-animations-js`.
/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
 import '@angular/localize/init';
 import 'zone.js'; // Included with Angular CLI.
